"use strict";

import $ from 'jquery';
window.jQuery = window.$ = $;

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import 'datatables.net-bs';
import 'datatables.net-buttons-bs';
import 'datatables.net-responsive-bs';
import 'datatables.net-searchbuilder-bs';

import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'datatables.net-buttons-bs/css/buttons.bootstrap.css';
import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import 'datatables.net-searchbuilder-bs/css/searchBuilder.bootstrap.css';

import './common.js';
import './common.css';
